import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import "../App.css";

import React, { useEffect, lazy, Suspense } from "react";
import axios from "axios";

const LazyHeader = lazy(() => import("../components/header/Header"));
const LazyHeader1 = lazy(() => import("../components/header/Header1"));
const LazyTrailPerformance = lazy(() =>
  import("../pages/trial-performance/TrailPerfomence")
);
const LazyShopPerfomence = lazy(() =>
  import("../pages/shop-perfomance/ShopPerfomence")
);
// start
const LazyLandingPage = lazy(() => import("../pages/home/LandingPage"));
const LazyLandingPage1 = lazy(() =>
  import("../components/subcomponents/landingpage2/Landingpage1")
);
const LazyPulsePartnerProgram = lazy(() =>
  import(
    "../components/subcomponents/pulse_partner_program/PulsePartnerProgram"
  )
);
const LazyUserSignIn1 = lazy(() => import("../pages/login/UserSignIn"));
const LazyUserSignIn = lazy(() =>
  import("../pages/login/SignInpage/UserSignIn")
);
const LazyUserSignUp = lazy(() => import("../pages/login/signup/SignUp"));
const LazyNoAccess = lazy(() => import("../pages/login/SignInpage/NoAccess"));
const LazyForgotPassword = lazy(() =>
  import("../pages/login/forgotpassword/ForgotPassword")
);
const LazyPulseLiteHomePage = lazy(() =>
  import("../pages/pulse-lite-home-page/PulseLiteHomePage")
);
const LazyPulseLiteHomePageMobile = lazy(() =>
  import("../pages/pulse-lite-home-page/PulseLiteHomePageMobile")
);
const LazyNewForgotPassword = lazy(() =>
  import("../pages/login/forgotpassword/NewForgotPassword")
);
const LazyAdditinalDetails = lazy(() =>
  import("../pages/login/signup/AdditinalDetails")
);
const LazyTeamOnboard = lazy(() =>
  import("../pages/login/SignInpage/TeamOnboard")
);
const LazyProfileSection = lazy(() =>
  import("../pages/profile-section/ProfileSection")
);
const LazyPulseLiteHeader = lazy(() =>
  import("../pulse_lite_components/pulseLiteHeader/PulseLiteHeader")
);
const LazyEmptyAdsScreen = lazy(() =>
  import("../pages/ads/EmptyAdsScreen/EmptyAdsScreen")
);
const LazyAdsMainContainer = lazy(() =>
  import("../pages/ads/AdsMainContainer")
);
const LazyCampaignMainContainer = lazy(() =>
  import("../components/subcomponents/campaign_page/CampaignMainContainer")
);
const LazyOffsiteAdsMainContainer = lazy(() =>
  import("../pages/ads/OffsiteAdsMainContainer")
);
const LazyTempleteHomePageNewUser = lazy(() =>
  import(
    "../components/subcomponents/research_page/atoms/newtemplates/templateHomePageRepeatNewUser/TempleteHomePageNewUser"
  )
);
const LazyStateTrailPage = lazy(() =>
  import("../components/subcomponents/state_page/StateTrailPage")
);
const LazyCategoryAnalysis = lazy(() =>
  import("../pages/category-analysis/CategoryAnalysis")
);

const LazyCompetetor = lazy(() =>
  import("../pages/competitor-analysis/Competetor")
);
const LazyConsumer = lazy(() => import("../pages/consumer-profile/Consumer"));
// const LazySentimentAnalysis = lazy(() =>
//   import("../pages/sentiment-analysis/PreviousSentimentAnalysis")
// );

const LazyResearchHomePage = lazy(() =>
  import("../pages/research/ResearchHomePage")
);
const LazySurveyStepper = lazy(() =>
  import(
    "../components/subcomponents/research_page/atoms/surveystepper/SurveyStepper"
  )
);
// const LazyUserSignIn = lazy(() => import("../pages/login/UserSignIn"));

const LazyCreateNew = lazy(() =>
  import("../components/subcomponents/research_page/atoms/create_new/CreateNew")
);
const LazyResultAnalysis = lazy(() =>
  import("../components/subcomponents/result_analysis/ResultAnalysis")
);
const LazyTemplateHomePage = lazy(() =>
  import(
    "../components/subcomponents/research_page/atoms/templates/TemplateHomePage"
  )
);

const LazyProductDetails = lazy(() =>
  import("../components/subcomponents/product_details_page/ProductDetails")
);
const LazyUsers = lazy(() => import("../pages/user-management/Users"));
const LazyTemplateLandingPage = lazy(() =>
  import("../components/subcomponents/templates1/TemplateLandingPage")
);
const LazyCreateNewTemplate = lazy(() =>
  import(
    "../components/subcomponents/templates1/atoms/newtemplate/CreateNewTemplate"
  )
);
const LazyBrandsManagement = lazy(() =>
  import("../pages/brands-management/BrandsManagement")
);
const LazyBrandDetails = lazy(() =>
  import(
    "../components/subcomponents/brand_management/atoms/brand_Deatils/BrandDetails"
  )
);
const LazyTemplateHomePageNew = lazy(() =>
  import(
    "../components/subcomponents/research_page/atoms/newtemplates/TemplateHomePageNew"
  )
);

const LazySurveyMainPage = lazy(() =>
  import(
    "../components/subcomponents/research_page/newSurveyatoms/surveymainpage/SurveyMainPage"
  )
);
const CrossTabAnalysis = lazy(() =>
  import(
    "../components/subcomponents/research_page/newSurveyatoms/cross_tab/CrossTabAnalysis"
  )
);

const LazyAdsBannerMainContainer = lazy(() =>
  import("../components/subcomponents/ads_banner_page/AdsBannerMainContainer")
);
const LazyCreateNewCampaign = lazy(() =>
  import("../components/subcomponents/create_new_campaign/CreateNewCampaign")
);
const LazyAdsManagement = lazy(() =>
  import("../pages/ads-management/AdsManagement")
);
const LazyAdsAdmin = lazy(() => import("../pages/ads-management/AdsAdmin"));
const LazyResultAnalysisShared = lazy(() =>
  import("../components/subcomponents/result_analysis/ResultAnalysisShared")
);
const LazyCampaignAdmin = lazy(() =>
  import(
    "../components/subcomponents/ads-management-page/atoms/campaignadmins/CampaignAdmin"
  )
);
const LazyCreateAudience = lazy(() =>
  import("../components/createaudience/CreateAudience")
);

const LazyCreateNewOffsitecampaign = lazy(() =>
  import(
    "../components/subcomponents/ads_offsite/create_new_offisite_campaign/CreateNewOffsitecampaign"
  )
);
const LazyOffsiteCampaignDetails = lazy(() =>
  import(
    "../components/subcomponents/ads_offsite/campaign_details/OffsiteCampaignDetails"
  )
);
const LazyAdDetailsMainContainer = lazy(() =>
  import(
    "../components/subcomponents/ads_offsite/ad_details/AdDetailsMainContainer"
  )
);
const LazyOffsiteCampaignAdmin = lazy(() =>
  import(
    "../components/subcomponents/ads-management-page/offsite_atoms/atoms/OffsiteCampaignAdmin"
  )
);

const LazyFinanceAdmin = lazy(() =>
  import("../components/subcomponents/finance_page/finance_admin/FinanceAdmin")
);

const LazyCustomEditor = lazy(() =>
  import(
    "../components/subcomponents/research_page/newSurveyatoms/question_types/CustomEditor"
  )
);
const LazyCustomEditor1 = lazy(() =>
  import(
    "../components/subcomponents/research_page/newSurveyatoms/question_types/CustomEditor1"
  )
);
const LazyOffsiteAdsAdmin = lazy(() =>
  import("../pages/ads-management/OffsiteAdsAdmin")
);

const LazyAudienceAugmentation = lazy(() =>
  import("../pages/ads-management/audienceAugmentation/AudienceAugmentation")
);
const LazyAdminSurveyList = lazy(() =>
  import(
    "../components/subcomponents/research_page/atoms/newtemplates/atoms/AdminSurveyList"
  )
);
const LazyAdSlotManagement = lazy(() =>
  import(
    "../pages/ads-management/audienceAugmentation/adSlotManagement/AdSlotManagement"
  )
);
const LazyResearchSlotManagement = lazy(() =>
  import(
    "../pages/ads-management/research-slot-management/ResearchSlotManagement"
  )
);
const LazyAudienceList = lazy(() =>
  import(
    "../pages/ads-management/audienceAugmentation/audiencelist/AudienceList"
  )
);
const LazyOrgAdmin = lazy(() => import("../pages/org-admin"));
const LazySmyttenAdmin = lazy(() => import("../pages/smytten-admin"));
const LazyCreateSuperAdminAccount = lazy(() =>
  import(
    "../components/subcomponents/account_management/atoms/CreateSuperAdminAccount/CreateSuperAdminAccount"
  )
);
const LazyOrgUserManagement = lazy(() => import("../pages/org-usermanagement"));
const LazyFinanceAccountTransfer = lazy(() =>
  import(
    "../components/subcomponents/finance_page/finance_account_transfer/FinanceAccountTransfer"
  )
);

const LazyCreateNewBrandTrack = lazy(() =>
  import(
    "../components/subcomponents/research_page/brand_track_atoms/create_brand_track/CreateNewBrandTrack"
  )
);
const LazyBrandTrackResults = lazy(() =>
  import(
    "../components/subcomponents/research_page/brand_track_atoms/brand_track_results/BrandTrackResults"
  )
);

const LazyBrandTrackResultsList = lazy(() =>
  import(
    "../components/subcomponents/research_page/brand_track_atoms/brand_track_results_list/BrandTrackResultsList"
  )
);
const LazyBrandTrackOverview = lazy(() =>
  import(
    "../components/subcomponents/research_page/brand_track_atoms/brand_track_results/brand_track-overview/BrandTrackOverview"
  )
);
const LazyResearchWaveTable = lazy(() =>
  import(
    "../components/subcomponents/research_page/brand_track_atoms/brand_track_results/research_wave_table/ResearchWaveTable"
  )
);
const LazyResearchWaveContent = lazy(() =>
  import(
    "../components/subcomponents/research_page/brand_track_atoms/brand_track_results/ResearchWaveContent/ResearchWaveContent"
  )
);
const LazyBrandTrackComparison = lazy(() =>
  import(
    "../components/subcomponents/research_page/brand_track_atoms/brand_track_results/brandtrack_comparison/BrandTrackComparison"
  )
);
const LazyBrandTrackComparisonCreation = lazy(() =>
  import(
    "../components/subcomponents/research_page/brand_track_atoms/brand_track_results/brandtrack_comparison/BrandTrackComparisonCreation"
  )
);
const LazyBrandtrackAdmin = lazy(() =>
  import(
    "../components/subcomponents/research_page/brand_track_atoms/brand_track_admin/BrandtrackAdmin"
  )
);
const LazyBrandtrackCrossTab = lazy(() =>
  import(
    "../components/subcomponents/research_page/brand_track_atoms/brand_track_results/cross_tab/BrandtrackCrossTab"
  )
);
const LazySentimentAnalysis1 = lazy(() =>
  import("../pages/sentiment-analysis/SentimentAnalysis")
);

const LazyDetailedViewOfConsumerSays = lazy(() =>
  import(
    "../components/subcomponents/sentiment_analysis/revamp/atoms/consumer_says/DetailedViewOfConsumerSays"
  )
);
const LazyLaunchSurvey = lazy(() =>
  import(
    "../components/subcomponents/sentiment_analysis/revamp/atoms/lunchsurvey/LaunchSurvey"
  )
);
const LazyCreateManualAudience = lazy(() =>
  import(
    "../pages/ads-management/audienceAugmentation/manualaudience/CreateManualAudience"
  )
);
const LazyManualAudienceList = lazy(() =>
  import(
    "../pages/ads-management/audienceAugmentation/manualaudience/ManualAudienceList"
  )
);
const LazyDetailedViewOfConsumerVoice = lazy(() =>
  import(
    "../components/subcomponents/sentiment_analysis/revamp/atoms/voice_of_consumers/DetailedViewOfConsumerVoice"
  )
);
const LazyCategoryAnalysisRevamp = lazy(() =>
  import("../pages/category-analysis/CategoryAnalysisRevamp")
);

const LazySpendsTracker = lazy(() =>
  import("../pages/spends-tracker/SpendsTracker")
);

const LazyBots = lazy(() =>
  import("../components/chat_bot/bots/Botlist/Botlist")
);
const LazyBotDetails = lazy(() =>
  import("../components/chat_bot/bots/Botlist/BotDetails")
);
const LazyCreateAgent = lazy(() =>
  import("../components/chat_bot/bots/Agents/CreateAgent")
);

const LazyCreateTool = lazy(() =>
  import("../components/chat_bot/bots/Tools/CreateTool")
);

const LazyFlowDetails = lazy(() =>
  import("../components/chat_bot/flow/Flow/FlowDeatils")
);
const LazyCreateHelperFunction = lazy(() =>
  import("../components/chat_bot/flow/HelperFunction/CreateHelperFunction")
);

const LazyAllAgents = lazy(() =>
  import("../components/chat_bot/bots/Agents/AllAgents")
);

const LazyAiSurvey = lazy(() =>
  import(
    "../components/subcomponents/sentiment_analysis/revamp/atoms/aisurvey/AiSurvey"
  )
);

const LazyLlmAgents = lazy(() =>
  import("../components/chat_bot/llmagents/LlmAgents")
);

const LazyClaudInterface = lazy(() =>
  import("../components/subcomponents/claud_interface/ClaudInterface")
);

export const RouteComponent1 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    const { pathname, search, hash } = location;

    // Check if the pathname ends with a slash and it's not the root path
    if (pathname.endsWith("/") && pathname !== "/") {
      // Construct new pathname without trailing slash
      const newPathname = pathname.slice(0, -1);
      // Navigate to the new URL without the trailing slash
      navigate(`${newPathname}${search}${hash}`, { replace: true });
    }
  }, [location, navigate]);

  axios.interceptors.request.use((request) => {
    if (token) {
      request.headers.Authorization = token;
    }
    return request;
  });

  return (
    <>
      <Routes>
        <Route
          path="/1"
          element={
            <Suspense>
              <LazyLandingPage />
            </Suspense>
          }
        />

        <Route
          path="/"
          element={
            <Suspense>
              <LazyLandingPage1 />
            </Suspense>
          }
        />

        <Route
          path="/lead"
          element={
            <Suspense>
              <LazyLandingPage1 />
            </Suspense>
          }
        />

        <Route
          path="/partners"
          element={
            <Suspense>
              <LazyPulsePartnerProgram />
            </Suspense>
          }
        />
        <Route exact path="/partner" element={<Navigate to="/partners" />} />

        <Route
          path="/brandsdashboard1"
          element={
            <Suspense>
              <LazyUserSignIn1 />
            </Suspense>
          }
        />

        <Route
          path="/brandsdashboard"
          element={
            <Suspense>
              <LazyUserSignIn />
            </Suspense>
          }
        />

        <Route
          path="/brandsdashboard/signup"
          element={
            <Suspense>
              <LazyUserSignUp />
            </Suspense>
          }
        />

        <Route
          path="/noaccess"
          element={
            <Suspense>
              <LazyHeader component={<LazyNoAccess />} />
            </Suspense>
          }
        />

        <Route
          path="/brandsdashboard1/forgotPassword"
          element={
            <Suspense>
              <LazyForgotPassword />
            </Suspense>
          }
        />

        <Route
          path="/pulse-lite-home-page"
          element={
            <Suspense>
              <LazyPulseLiteHomePage />
            </Suspense>
          }
        />

        <Route
          path="/pulse-lite-home-page-mobile"
          element={
            <Suspense>
              <LazyPulseLiteHomePageMobile />
            </Suspense>
          }
        />

        <Route
          path="/brandsdashboard/forgotPassword"
          element={
            <Suspense>
              <LazyNewForgotPassword />
            </Suspense>
          }
        />

        <Route
          path="/brandsdashboard/forgotPassword/confirmation"
          element={
            <Suspense>
              <LazyForgotPassword />
            </Suspense>
          }
        />

        <Route
          path="/brandsdashboard/additionaldetails/:id"
          element={
            <Suspense>
              <LazyAdditinalDetails />
            </Suspense>
          }
        />

        <Route
          path="/brandsdashboard/team-onboard"
          element={
            <Suspense>
              <LazyTeamOnboard />
            </Suspense>
          }
        />

        <Route
          path="/profile-details"
          element={
            <Suspense>
              <LazyPulseLiteHeader component={<LazyProfileSection />} />
            </Suspense>
          }
        />

        <Route
          path="/myads1_lite"
          element={
            <Suspense>
              <LazyPulseLiteHeader component={<LazyEmptyAdsScreen />} />
            </Suspense>
          }
        />

        <Route
          path="/myads_lite"
          element={
            <Suspense>
              <LazyPulseLiteHeader component={<LazyAdsMainContainer />} />
            </Suspense>
          }
        />

        <Route
          path="/myads_lite/campaign/:id"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyCampaignMainContainer />} />
            </Suspense>
          }
        />

        <Route
          path="/offsiteads_lite"
          element={
            <Suspense>
              <LazyPulseLiteHeader
                component={<LazyOffsiteAdsMainContainer />}
              />
            </Suspense>
          }
        />

        <Route
          path="/researchlite"
          element={
            <Suspense>
              <LazyPulseLiteHeader
                component={<LazyTempleteHomePageNewUser />}
              />
            </Suspense>
          }
        />

        <Route
          path="/consumer_research_lite"
          element={
            <Suspense>
              <LazyPulseLiteHeader
                component={<LazyTempleteHomePageNewUser />}
              />
            </Suspense>
          }
        />

        <Route
          path="/brand_track_lite"
          element={
            <Suspense>
              <LazyPulseLiteHeader
                component={<LazyTempleteHomePageNewUser />}
              />
            </Suspense>
          }
        />

        <Route
          path="/quick_surveys_lite"
          element={
            <Suspense>
              <LazyPulseLiteHeader
                component={<LazyTempleteHomePageNewUser />}
              />
            </Suspense>
          }
        />

        <Route
          path="/trialperformance"
          element={
            <Suspense>
              <LazyHeader component={<LazyTrailPerformance />} />
            </Suspense>
          }
        />

        <Route
          path="/trialperformance/:stateName"
          element={
            <Suspense>
              <LazyHeader component={<LazyStateTrailPage />} />
            </Suspense>
          }
        />

        <Route
          path="/shopperformance"
          element={
            <Suspense>
              <LazyHeader component={<LazyShopPerfomence />} />
            </Suspense>
          }
        />

        <Route
          path="/categoryanalysis"
          element={
            <Suspense>
              <LazyHeader component={<LazyCategoryAnalysis />} />
            </Suspense>
          }
        />
        <Route
          path="/categoryanalysis1"
          element={
            <Suspense>
              <LazyHeader component={<LazyCategoryAnalysisRevamp />} />
            </Suspense>
          }
        />
        <Route
          path="/myads"
          element={
            <Suspense>
              <LazyHeader component={<LazyAdsMainContainer />} />
            </Suspense>
          }
        />
        <Route
          path="/myads/campaign/:id"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyCampaignMainContainer />} />
            </Suspense>
          }
        />
        <Route
          path="/myads/newcampaign"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyCreateNewCampaign />} />
            </Suspense>
          }
        />
        <Route
          path="/myads/newcampaign/:id"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyCreateNewCampaign />} />
            </Suspense>
          }
        />
        <Route
          path="/myads/ads/:id"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyAdsBannerMainContainer />} />
            </Suspense>
          }
        />
        <Route
          path="/myads1"
          element={
            <Suspense>
              <LazyHeader component={<LazyEmptyAdsScreen />} />
            </Suspense>
          }
        />
        <Route
          path="/offsiteads"
          element={
            <Suspense>
              <LazyHeader component={<LazyOffsiteAdsMainContainer />} />
            </Suspense>
          }
        />
        <Route
          path="/offsiteads/newcampaign/:id/"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyCreateNewOffsitecampaign />} />
            </Suspense>
          }
        />
        <Route
          path="/offsiteads/campaign/:id"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyOffsiteCampaignDetails />} />
            </Suspense>
          }
        />
        <Route
          path="/offsiteads/ads/:id"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyAdDetailsMainContainer />} />
            </Suspense>
          }
        />
        <Route
          path="/offsiteads/newcampaign"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyCreateNewOffsitecampaign />} />
            </Suspense>
          }
        />

        <Route
          path="/adsadmin"
          element={
            <Suspense>
              <LazyHeader component={<LazyAdsAdmin />} />
            </Suspense>
          }
        />
        <Route
          path="/adsadmin/campaign/:id"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyCampaignAdmin />} />
            </Suspense>
          }
        />
        <Route
          path="/offsiteadsadmin"
          element={
            <Suspense>
              <LazyHeader component={<LazyOffsiteAdsAdmin />} />
            </Suspense>
          }
        />

        <Route
          path="/offsiteadsadmin/campaign/:id"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyOffsiteCampaignAdmin />} />
            </Suspense>
          }
        />

        <Route
          path="/adslotmanagement"
          element={
            <Suspense>
              <LazyHeader component={<LazyAdSlotManagement />} />
            </Suspense>
          }
        />

        {/* audience ingestion */}

        <Route
          path="/manualaudienceaugmentation"
          element={
            <Suspense>
              <LazyHeader component={<LazyCreateManualAudience />} />
            </Suspense>
          }
        />
        <Route
          path="/manualaudienceaugmentation/:id"
          element={
            <Suspense>
              <LazyHeader component={<LazyCreateManualAudience />} />
            </Suspense>
          }
        />
        <Route
          path="/manualaudienceaugmentationlist"
          element={
            <Suspense>
              <LazyHeader component={<LazyManualAudienceList />} />
            </Suspense>
          }
        />

        <Route
          path="/audienceaugmentation"
          element={
            <Suspense>
              <LazyHeader component={<LazyAudienceAugmentation />} />
            </Suspense>
          }
        />

        <Route
          path="/audienceaugmentation/:id"
          element={
            <Suspense>
              <LazyHeader component={<LazyAudienceAugmentation />} />
            </Suspense>
          }
        />

        <Route
          path="/audienceaugmentationlist"
          element={
            <Suspense>
              <LazyHeader component={<LazyAudienceList />} />
            </Suspense>
          }
        />

        <Route
          path="/manualaudienceaugmentationlist"
          element={
            <Suspense>
              <LazyHeader component={<LazyManualAudienceList />} />
            </Suspense>
          }
        />
        <Route
          path="/manualaudienceaugmentation"
          element={
            <Suspense>
              <LazyHeader component={<LazyCreateManualAudience />} />
            </Suspense>
          }
        />
        <Route
          path="/manualaudienceaugmentation/:id"
          element={
            <Suspense>
              <LazyHeader component={<LazyCreateManualAudience />} />
            </Suspense>
          }
        />

        {/* sentimentanalysis */}
        {/* <Route
          path="/sentimentanalysis"
          element={
            <Suspense>
              <LazyHeader component={<LazySentimentAnalysis />} />
            </Suspense>
          }
        /> */}
        <Route
          path="/sentimentanalysis"
          element={
            <Suspense>
              <LazyHeader component={<LazySentimentAnalysis1 />} />
            </Suspense>
          }
        />

        <Route
          path="/sentimentanalysis/consumervoice/:type"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyDetailedViewOfConsumerVoice />} />
            </Suspense>
          }
        />

        <Route
          path="/sentimentanalysis/consumersays/:id"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyDetailedViewOfConsumerSays />} />
            </Suspense>
          }
        />
        <Route
          path="/sentimentanalysis/launchsurvey/:surveyId?"
          element={
            <Suspense>
              <LazyLaunchSurvey />
            </Suspense>
          }
        />
        <Route
          path="/aisurvey/:surveyId?"
          element={
            <Suspense>
              <LazyAiSurvey />
            </Suspense>
          }
        />

        <Route
          path="/competitoranalysis"
          element={
            <Suspense>
              <LazyHeader component={<LazyCompetetor />} />
            </Suspense>
          }
        />
        <Route
          path="/consumerprofile"
          element={
            <Suspense>
              <LazyHeader component={<LazyConsumer />} />
            </Suspense>
          }
        />

        {/* research */}

        <Route
          path="/research/instantiatesurvey"
          element={
            <Suspense>
              <LazyHeader component={<LazyCreateNew />} />
            </Suspense>
          }
        />

        <Route
          path="/research_1"
          element={
            <Suspense>
              <LazyHeader component={<LazyResearchHomePage />} />
            </Suspense>
          }
        />

        <Route
          path="/research"
          element={
            <Suspense>
              <LazyHeader
                component={<LazyTempleteHomePageNewUser />}
                backgroundChange="background"
              />
            </Suspense>
          }
        />

        <Route
          path="/research/templates"
          element={
            <Suspense>
              <LazyHeader component={<LazyTemplateHomePage />} />
            </Suspense>
          }
        />

        <Route
          path="/research/templates_1"
          element={
            <Suspense>
              <LazyHeader component={<LazyTemplateHomePageNew />} />
            </Suspense>
          }
        />

        <Route
          path="/research/surveys/"
          element={
            <Suspense>
              <LazyHeader1 component={<LazySurveyMainPage />} />
            </Suspense>
          }
        />

        <Route
          path="/research/surveys_1/"
          element={
            <Suspense>
              <LazyHeader component={<LazySurveyStepper />} />
            </Suspense>
          }
        />

        <Route
          path="/research/surveys_1/:surveyId/"
          element={
            <Suspense>
              <LazyHeader component={<LazySurveyStepper />} />
            </Suspense>
          }
        />

        <Route
          path="/research/surveys/:surveyId/"
          element={
            <Suspense>
              <LazyHeader1 component={<LazySurveyMainPage />} />
            </Suspense>
          }
        />

        <Route
          path="/research/surveycrosstab/:surveyId/:crossTabId?"
          element={
            <Suspense>
              <LazyHeader1 component={<CrossTabAnalysis />} />
            </Suspense>
          }
        />

        <Route
          path="/research/resultAnalysis/:surveyId"
          element={
            <Suspense>
              <LazyHeader component={<LazyResultAnalysis />} />
            </Suspense>
          }
        />

        <Route
          path="/research/resultAnalysisShared/:surveyId"
          element={
            <Suspense>
              <LazyResultAnalysisShared />
            </Suspense>
          }
        />

        <Route
          path="/research/resultAnalysis_v2/:surveyId"
          element={
            <Suspense>
              <LazyHeader component={<LazyResultAnalysis name="duplicate" />} />
            </Suspense>
          }
        />

        {/* BRAND TRACK ROUTES */}

        <Route
          path="/brandtrackadmin/"
          element={
            <Suspense>
              <LazyHeader component={<LazyBrandtrackAdmin />} />
            </Suspense>
          }
        />

        <Route
          path="/research/createbrandtrack"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyCreateNewBrandTrack />} />
            </Suspense>
          }
        />

        <Route
          path="/research/createbrandtrack/:brand_track_id"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyCreateNewBrandTrack />} />
            </Suspense>
          }
        />

        <Route
          path="/research/brandtracklist"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyBrandTrackResultsList />} />
            </Suspense>
          }
        />

        <Route
          path="/research/overview"
          element={
            <Suspense>
              <LazyHeader1 component={<LazyBrandTrackResults />} />
            </Suspense>
          }
        />

        <Route
          path="/research/brandtrack/:id/rvid/:resultviewid/sid/:sectionid"
          element={
            <Suspense>
              <LazyHeader1
                component={
                  <LazyBrandTrackResults
                    Component={<LazyBrandTrackOverview />}
                  />
                }
              />
            </Suspense>
          }
        />

        <Route
          path="/research/brandtrack/:id/rvid/:resultviewid/wave/:waveid/sid/:sectionid/"
          element={
            <Suspense>
              <LazyHeader1
                component={
                  <LazyBrandTrackResults
                    Component={<LazyResearchWaveContent />}
                  />
                }
              />
            </Suspense>
          }
        />

        <Route
          path="research/researchwave/brandtrack/:id/rvid/:resultviewid/sid/:sectionid"
          element={
            <Suspense>
              <LazyHeader1
                component={
                  <LazyBrandTrackResults
                    Component={<LazyResearchWaveTable />}
                  />
                }
              />
            </Suspense>
          }
        />

        <Route
          path="/research/researchwave/brandtrack"
          element={
            <Suspense>
              <LazyHeader1
                component={
                  <LazyBrandTrackResults
                    Component={<LazyResearchWaveTable />}
                  />
                }
              />
            </Suspense>
          }
        />

        <Route
          path="/research/comparison/brandtrack/:id/rvid/:resultviewid/sid/:sectionid"
          element={
            <Suspense>
              <LazyHeader1
                component={
                  <LazyBrandTrackResults
                    Component={<LazyBrandTrackComparisonCreation />}
                  />
                }
              />
            </Suspense>
          }
        />

        <Route
          path="/research/crosstab/brandtrack/:id/rvid/:resultviewid/sid/:sectionid"
          element={
            <Suspense>
              <LazyHeader1
                component={
                  <LazyBrandTrackResults
                    Component={<LazyBrandtrackCrossTab />}
                  />
                }
              />
            </Suspense>
          }
        />

        <Route
          path="/research/comparison/brandtrack/:id/rvid/:resultviewid/brandtrack2id/:brandtrack2id/rv2id/:resultview2id/sid/:sectionid"
          element={
            <Suspense>
              <LazyHeader1
                component={
                  <LazyBrandTrackResults
                    Component={<LazyBrandTrackComparison />}
                  />
                }
              />
            </Suspense>
          }
        />

        <Route
          path="/brandtrackadmin"
          element={
            <Suspense>
              <LazyHeader component={<LazyBrandtrackAdmin />} />
            </Suspense>
          }
        />

        {/* ADMIN PAGES */}

        <Route
          path="/researchadmin/"
          element={
            <Suspense>
              <LazyHeader component={<LazyAdminSurveyList />} />
            </Suspense>
          }
        />

        <Route
          path="/adsmanagement"
          element={
            <Suspense>
              <LazyHeader component={<LazyAdsManagement />} />
            </Suspense>
          }
        />

        <Route
          path="/researchslotmanagement"
          element={
            <Suspense>
              <LazyHeader component={<LazyResearchSlotManagement />} />
            </Suspense>
          }
        />
        <Route
          path="/spendstracker"
          element={
            <Suspense>
              <LazyHeader component={<LazySpendsTracker />} />
            </Suspense>
          }
        />

        <Route
          path="/brandmanagement"
          element={
            <Suspense>
              <LazyHeader
                component={<LazyBrandsManagement name="duplicate" />}
              />
            </Suspense>
          }
        />

        <Route
          path="/brandmanagement/:brandId"
          element={
            <Suspense>
              <LazyHeader component={<LazyBrandDetails />} />
            </Suspense>
          }
        />

        <Route
          path="/edittemplate"
          element={
            <Suspense>
              <LazyHeader component={<LazyTemplateLandingPage />} />
            </Suspense>
          }
        />

        <Route
          path="/edittemplate/:surveyId"
          element={
            <Suspense>
              <LazyHeader component={<LazyCreateNewTemplate />} />
            </Suspense>
          }
        />

        <Route
          path="/edittemplate/newtemplate"
          element={
            <Suspense>
              <LazyHeader component={<LazyCreateNewTemplate />} />
            </Suspense>
          }
        />

        <Route
          path="/finance"
          element={
            <Suspense>
              <LazyHeader component={<LazyFinanceAdmin />} />
            </Suspense>
          }
        />

        <Route
          path="/account-transfer"
          element={
            <Suspense>
              <LazyHeader component={<LazyFinanceAccountTransfer />} />
            </Suspense>
          }
        />

        <Route
          path="/admin-accounts"
          element={
            <Suspense>
              <LazyHeader component={<LazySmyttenAdmin />} />
            </Suspense>
          }
        />

        <Route
          path="/admin-accounts/organisations/create"
          element={
            <Suspense>
              <LazyHeader
                component={<LazyCreateSuperAdminAccount />}
                accounts={true}
              />
            </Suspense>
          }
        />

        <Route
          path="/admin-accounts/organisations/:id"
          element={
            <Suspense>
              <LazyHeader
                component={<LazyCreateSuperAdminAccount />}
                accounts={true}
              />
            </Suspense>
          }
        />

        <Route
          path="/org-accounts"
          element={
            <Suspense>
              <LazyHeader component={<LazyOrgAdmin />} accounts={true} />
            </Suspense>
          }
        />

        <Route
          path="/org-usermanagement/"
          element={
            <Suspense>
              <LazyHeader
                component={<LazyOrgUserManagement />}
                accounts={true}
              />
            </Suspense>
          }
        />

        <Route
          path="/spendstracker/"
          element={
            <Suspense>
              <LazyHeader component={<LazySpendsTracker />} accounts={true} />
            </Suspense>
          }
        />

        <Route
          path="/bots"
          element={
            <Suspense>
              <LazyHeader component={<LazyBots></LazyBots>} />
            </Suspense>
          }
        />
        <Route
          path="/bots/details/:botid"
          element={
            <Suspense>
              <LazyHeader component={<LazyBotDetails></LazyBotDetails>} />
            </Suspense>
          }
        />

        <Route
          path="/bots/:botid/agent/:id?"
          element={
            <Suspense>
              <LazyHeader component={<LazyCreateAgent></LazyCreateAgent>} />
            </Suspense>
          }
        />

        <Route
          path="/bots/:botid/tool/:id?"
          element={
            <Suspense>
              <LazyHeader component={<LazyCreateTool></LazyCreateTool>} />
            </Suspense>
          }
        />

        <Route
          path="/bots/:botid/flow/:flowid/"
          element={
            <Suspense>
              <LazyHeader component={<LazyFlowDetails />} />
            </Suspense>
          }
        />
        <Route
          path="/bots/:botid/helperfunction/:h_id?"
          element={
            <Suspense>
              <LazyHeader component={<LazyCreateHelperFunction />} />
            </Suspense>
          }
        />
        <Route
          path="/bots/:botid/flow/:flowid/helperfunction/:h_id?"
          element={
            <Suspense>
              <LazyHeader component={<LazyCreateHelperFunction />} />
            </Suspense>
          }
        />
        <Route
          path="/bots/:botid/allagents"
          element={
            <Suspense>
              <LazyHeader component={<LazyAllAgents />} />
            </Suspense>
          }
        />

        <Route
          path="/llmagents/:llmagent_id?"
          element={
            <Suspense>
              <LazyHeader component={<LazyLlmAgents />} />
            </Suspense>
          }
        />
        <Route
          path="/claudsurvey"
          element={
            <Suspense>
              <LazyClaudInterface />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
};
